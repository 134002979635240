import clsx from 'clsx';
import { ReactNode, useRef } from 'react';

import { FooterQuery, NavigationQuery } from '@/api';
import { Footer } from '@/components/footer/Footer';
import { Navigation } from '@/components/navigation/Navigation';

type Props = {
  children: ReactNode;
  footerData: FooterQuery;
  headerData: NavigationQuery;
};

export const Layout = ({ children, footerData, headerData }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <main ref={ref} className={clsx('scroll-smooth')}>
      <Navigation data={headerData} />
      <div className="pt-[80px] md:pt-[155px]">{children}</div>
      <Footer data={footerData} />
    </main>
  );
};
