import clsx from 'clsx';
import { forwardRef } from 'react';

export interface GlobalWrapperSectionProps {
  children: JSX.Element;
  classNameInner?: string;
  classNameWrapper?: string;
  enabledMobilePaddingX?: boolean;
  enabledMobilePaddingY?: boolean;
  enabledDesktopPaddingX?: boolean;
  enabledDesktopPaddingY?: boolean;
  enabledGlobalPaddings?: boolean;
  maxDesktopWidth?: boolean;
  anchor?: string;
  hasScrollableDiv?: boolean;
}

const GlobalWrapperSection = forwardRef<
  HTMLElement | null,
  GlobalWrapperSectionProps
>(
  (
    {
      children,
      classNameInner,
      classNameWrapper,
      enabledMobilePaddingX = true,
      enabledMobilePaddingY = true,
      enabledDesktopPaddingX = true,
      enabledDesktopPaddingY = true,
      enabledGlobalPaddings = true,
      maxDesktopWidth = true,
      anchor,
      hasScrollableDiv = false,
    },
    ref,
  ) => {
    return (
      <section
        ref={ref || undefined}
        className={clsx('flex w-full justify-center', classNameWrapper)}
        id={anchor}
      >
        <div
          className={clsx(
            'flex w-full',
            enabledMobilePaddingX && enabledGlobalPaddings && 'px-4',
            enabledMobilePaddingY &&
              enabledGlobalPaddings &&
              !hasScrollableDiv &&
              'py-15',
            enabledMobilePaddingY &&
              enabledGlobalPaddings &&
              hasScrollableDiv &&
              'py-15 md:pb-14',
            enabledDesktopPaddingX && enabledGlobalPaddings && 'xl:px-10',
            enabledDesktopPaddingY &&
              enabledGlobalPaddings &&
              !hasScrollableDiv &&
              'md:py-20',
            enabledDesktopPaddingY &&
              enabledGlobalPaddings &&
              hasScrollableDiv &&
              'md:pb-[76px] md:pt-20',
            maxDesktopWidth && 'max-w-desktop',
            classNameInner,
          )}
        >
          {children}
        </div>
      </section>
    );
  },
);

GlobalWrapperSection.displayName = 'GlobalWrapperSection';

export default GlobalWrapperSection;
