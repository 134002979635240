import { useWindowSize } from '@/hooks/useWindowSize';

interface NavLinkProps {
  link: string;
  label: string;
  openInNewTab?: boolean;
}

const NavLink = ({ link, label, openInNewTab = false }: NavLinkProps) => {
  const { width } = useWindowSize();

  return (
    <li>
      <a
        href={link}
        className="inline-block text-p leading-loose text-collapo-black-80 transition-colors duration-300 hover:text-collapo-blue motion-reduce:transition-none"
        target={width && width > 1024 && openInNewTab ? '_blank' : '_self'}
      >
        {label}
      </a>
    </li>
  );
};

export default NavLink;
