import { useWindowSize } from '@/hooks/useWindowSize';

interface BottomNavLinkProps {
  link: string;
  label: string;
  openInNewTab?: boolean;
}

const BottomNavLink = ({
  link,
  label,
  openInNewTab = false,
}: BottomNavLinkProps) => {
  const { width } = useWindowSize();

  return (
    <a
      href={link}
      className="px-3 text-p text-collapo-white transition-colors duration-300 hover:text-collapo-blue-20 motion-reduce:transition-none"
      target={width && width > 1024 && openInNewTab ? '_blank' : '_self'}
    >
      {label}
    </a>
  );
};

export default BottomNavLink;
