import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  toggled: boolean;
  toggle: Dispatch<SetStateAction<boolean>>;
  color: 'black' | 'white';
};

const HamburgerButton = ({ toggled, toggle, color }: Props) => {
  return (
    <button
      onClick={() => toggle((prev) => !prev)}
      className="flex size-[26px] flex-col items-center justify-center gap-[2px] xl:hidden"
      aria-label="Show navigation"
    >
      <span
        className={clsx(
          'block h-0.5 w-6  transition-all duration-300 ease-out',
          toggled ? 'translate-y-0.5 rotate-45' : '-translate-y-0.5',
          color === 'black' ? 'bg-collapo-black' : 'bg-collapo-white',
        )}
      />
      <span
        className={clsx(
          'block h-0.5 w-6 transition-all duration-300 ease-out',
          toggled ? '-translate-y-0.5 -rotate-45' : 'translate-y-0.5',
          color === 'black' ? 'bg-collapo-black' : 'bg-collapo-white',
        )}
      />
    </button>
  );
};

export default HamburgerButton;
