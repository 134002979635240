import Link from 'next/link';
import { Fragment } from 'react';

import { FooterQuery } from '@/api';
import BottomNavLink from '@/components/footer/BottomNavLink';
import LinkGroup from '@/components/footer/LinkGroup';
import NavLink from '@/components/footer/NavLink';
import Picture from '@/components/Picture/Picture';
import { projectConfig } from '@/constants/projectConfig';

export const Footer = ({ data }: { data: FooterQuery }) => {
  const footerData = data?.footer?.data?.attributes;

  const columns = (footerData?.column || []).map((column) => ({
    label: column?.label || '',
    links: (column?.link || []).map((link) => ({
      label: link?.label || '',
      path: link?.path || '',
      openInNewTab: link?.openInNewTab || undefined,
    })),
  }));

  const policies = {
    label: footerData?.policies?.label || '',
    links: (footerData?.policies?.link || []).map((link) => ({
      path: link?.path || '',
      label: link?.label || '',
      openInNewTab: link?.openInNewTab || undefined,
    })),
  };

  const contact = {
    title: footerData?.contact?.label || '',
    phone: {
      label: footerData?.contact?.phone?.label || '',
      path: footerData?.contact?.phone?.path || '',
      openInNewTab: footerData?.contact?.phone?.openInNewTab || undefined,
    },
    email: {
      label: footerData?.contact?.email?.label || '',
      path: footerData?.contact?.email?.path || '',
      openInNewTab: footerData?.contact?.email?.openInNewTab || undefined,
    },
  };

  return (
    <div className="flex w-full justify-center bg-collapo-white">
      <footer className="flex w-full flex-wrap justify-center">
        <div className="flex w-full max-w-desktop flex-wrap gap-y-20 px-4 py-15 md:gap-y-12 xl:gap-y-20 xl:px-10 xl:py-20">
          <div className="w-full sm:w-2/3 lg:mr-auto lg:w-2/5">
            <div className="mb-10 w-full">
              <Link href="/" className="mb-6 inline-block max-w-[160px]">
                <Picture
                  imgSrc={`${projectConfig.cloudinaryProjectName}/collapo-logo`}
                  alt="Collapo logo"
                  classNameImage="h-[52px] w-auto xs:h-[80px] md:h-[115px]"
                  classNameWrapper="mx-[22px]"
                  width={160}
                  height={60}
                  enabledLazyLoading={false}
                />
              </Link>
              {contact.phone && (
                <p className="mb-6 flex items-center text-p font-medium text-collapo-black">
                  <span className="mr-3 text-collapo-blue">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_941_15626)">
                        <path
                          d="M15.1875 19.4688C14.3438 19.4688 13.375 19.25 12.3125 18.8438C10.1875 18 7.84377 16.375 5.75002 14.2813C3.65627 12.1875 2.03127 9.84377 1.18752 7.68752C0.250019 5.37502 0.343769 3.46877 1.43752 2.40627C1.46877 2.37502 1.53127 2.34377 1.56252 2.31252L4.18752 0.750025C4.84377 0.375025 5.68752 0.562525 6.12502 1.18752L7.96877 3.93753C8.40627 4.59378 8.21877 5.46877 7.59377 5.90627L6.46877 6.68752C7.28127 8.00002 9.59377 11.2188 13.2813 13.5313L13.9688 12.5313C14.5 11.7813 15.3438 11.5625 16.0313 12.0313L18.7813 13.875C19.4063 14.3125 19.5938 15.1563 19.2188 15.8125L17.6563 18.4375C17.625 18.5 17.5938 18.5313 17.5625 18.5625C17 19.1563 16.1875 19.4688 15.1875 19.4688ZM2.37502 3.46878C1.78127 4.12503 1.81252 5.46877 2.50002 7.18752C3.28127 9.15627 4.78127 11.3125 6.75002 13.2813C8.68752 15.2188 10.875 16.7188 12.8125 17.5C14.5 18.1875 15.8438 18.2188 16.5313 17.625L18.0313 15.0625C18.0313 15.0313 18.0313 15.0313 18.0313 15L15.2813 13.1563C15.2813 13.1563 15.2188 13.1875 15.1563 13.2813L14.4688 14.2813C14.0313 14.9063 13.1875 15.0938 12.5625 14.6875C8.62502 12.25 6.18752 8.84377 5.31252 7.46877C4.90627 6.81252 5.06252 5.96878 5.68752 5.53128L6.81252 4.75002V4.71878L4.96877 1.96877C4.96877 1.93752 4.93752 1.93752 4.90627 1.96877L2.37502 3.46878Z"
                          fill="currentColor"
                        />
                        <path
                          d="M18.3125 8.90633C17.9375 8.90633 17.6563 8.62508 17.625 8.25008C17.375 5.09383 14.7813 2.56258 11.5938 2.34383C11.2188 2.31258 10.9063 2.00008 10.9375 1.59383C10.9688 1.21883 11.2813 0.906333 11.6875 0.937583C15.5625 1.18758 18.7188 4.25008 19.0313 8.12508C19.0625 8.50008 18.7813 8.84383 18.375 8.87508C18.375 8.90633 18.3438 8.90633 18.3125 8.90633Z"
                          fill="currentColor"
                        />
                        <path
                          d="M15.2187 9.18755C14.875 9.18755 14.5625 8.93755 14.5312 8.56255C14.3437 6.87505 13.0312 5.56255 11.3437 5.3438C10.9687 5.31255 10.6875 4.93755 10.7187 4.56255C10.75 4.18755 11.125 3.9063 11.5 3.93755C13.8437 4.2188 15.6562 6.0313 15.9375 8.37505C15.9687 8.75005 15.7187 9.0938 15.3125 9.1563C15.25 9.18755 15.2187 9.18755 15.2187 9.18755Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_941_15626">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>
                    <a
                      href={contact.phone.path}
                      className="text-collapo-black transition-colors duration-300 hover:text-collapo-blue motion-reduce:transition-none"
                    >
                      {contact.phone.label}
                    </a>
                  </span>
                </p>
              )}
              {contact.email.path && (
                <p className="flex items-center text-p font-medium text-collapo-black">
                  <span className="mr-3 text-collapo-blue">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 3.59998H3.00001C1.72501 3.59998 0.637512 4.64998 0.637512 5.96248V18.1125C0.637512 19.3875 1.68751 20.475 3.00001 20.475H21C22.275 20.475 23.3625 19.425 23.3625 18.1125V5.92498C23.3625 4.64998 22.275 3.59998 21 3.59998ZM21 5.28748C21.0375 5.28748 21.075 5.28748 21.1125 5.28748L12 11.1375L2.88751 5.28748C2.92501 5.28748 2.96251 5.28748 3.00001 5.28748H21ZM21 18.7125H3.00001C2.62501 18.7125 2.32501 18.4125 2.32501 18.0375V6.93748L11.1 12.5625C11.3625 12.75 11.6625 12.825 11.9625 12.825C12.2625 12.825 12.5625 12.75 12.825 12.5625L21.6 6.93748V18.075C21.675 18.45 21.375 18.7125 21 18.7125Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span>
                    <a
                      href={contact.email.path}
                      className="text-collapo-black transition-colors duration-300 hover:text-collapo-blue motion-reduce:transition-none"
                    >
                      {contact.email.label}
                    </a>
                  </span>
                </p>
              )}
            </div>
          </div>
          {columns.map(({ links, label }, index) => (
            <Fragment key={`menuItems-columns-footer-${index}`}>
              <LinkGroup header={label}>
                {links.map(({ label, path, openInNewTab }, index) => (
                  <Fragment key={`menuItems-columns-links-footer-${index}`}>
                    <NavLink
                      link={path}
                      label={label}
                      openInNewTab={openInNewTab}
                    />
                  </Fragment>
                ))}
              </LinkGroup>
            </Fragment>
          ))}
        </div>
        <div className="flex w-full justify-center bg-collapo-blue">
          <div className="flex w-full max-w-desktop flex-wrap gap-y-10 px-4 py-8 md:gap-y-6 xl:gap-y-10 xl:p-10">
            <div className="w-full px-4 md:w-1/3 lg:w-1/2">
              <div className="my-1 flex justify-center md:justify-start">
                <p className="text-p text-collapo-white">
                  &copy; {new Date().getFullYear()} Collapo
                </p>
              </div>
            </div>
            <div className="w-full px-4 md:w-1/3 lg:w-1/2">
              <div className="my-1">
                <div className="-mx-3 flex flex-wrap items-center justify-center md:justify-end">
                  {policies.links.map(
                    ({ label, path, openInNewTab }, index) => (
                      <Fragment key={`menuItems-policies-footer-${index}`}>
                        <BottomNavLink
                          link={path}
                          label={label}
                          openInNewTab={openInNewTab}
                        />
                      </Fragment>
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
