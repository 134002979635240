import clsx from 'clsx';
import Link from 'next/link';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import Dropdown from '@/components/header/Dropdown';
import DropdownItem from '@/components/header/DropdownItem';
import HamburgerButton from '@/components/header/HamburgerButton';
import LinkItem from '@/components/header/LinkItem';
import ListItem from '@/components/header/ListItem';
import { HeaderProps } from '@/components/navigation/Navigation';
import Picture from '@/components/Picture/Picture';
import { projectConfig } from '@/constants/projectConfig';
import { useCloseByEsc } from '@/hooks/useCloseByEsc';
import { useToggleBodyClasses } from '@/hooks/useToggleBodyClasses';
import { useWindowSize } from '@/hooks/useWindowSize';

const Header = ({ menuItems, activePath }: HeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useToggleBodyClasses({ isOpen: isMenuOpen });

  useCloseByEsc({ handleClose: () => setIsMenuOpen(false) });

  const handleScroll = useCallback(() => {
    setIsScrolled(window.scrollY > 20);
  }, []);

  const { width } = useWindowSize();

  const memoMenuItems = useMemo(() => {
    return menuItems.map((item) => ({
      ...item,
    }));
  }, [menuItems]);

  const bgChangeThemed = isScrolled && !isMenuOpen;

  const headerBgClass = clsx(
    isMenuOpen
      ? 'max-h-full'
      : isScrolled
      ? 'max-h-[80px] md:max-h-[115px]'
      : 'max-h-[80px] md:max-h-[155px]',
    {
      'bg-collapo-blue': bgChangeThemed,
      'bg-collapo-black-10': isScrolled || isMenuOpen,
      'bg-collapo-white': !isScrolled && !isMenuOpen,
    },
  );

  return (
    <header
      className={clsx(
        'fixed left-0 top-0 z-50 flex h-full w-screen justify-center transition-all duration-700',
        headerBgClass,
      )}
    >
      <div
        className={clsx(
          'left-0 flex w-full origin-top justify-center transition-all',
        )}
      >
        <div
          className={clsx(
            'relative w-full max-w-desktop px-4 sm:pl-4 sm:pr-8 xl:px-10',
          )}
        >
          <div className="relative -mx-4 flex items-center justify-between">
            <div className="w-60 max-w-full px-4">
              <Link
                href="/"
                className={clsx(
                  'relative block h-[80px] w-[92px] transition-all duration-700 md:h-[115px] md:w-[133px]',
                  isScrolled ? 'py-0' : 'md:py-5',
                )}
              >
                <Picture
                  imgSrc={`${projectConfig.cloudinaryProjectName}/collapo-logo-white`}
                  alt="Collapo logo"
                  classNameImage="w-auto h-[80px] md:h-[115px]"
                  classNameWrapper={clsx(
                    'absolute inset-0 mx-[22px] w-full transition-all duration-700 motion-reduce:transition-none',
                    bgChangeThemed ? 'opacity-100' : 'opacity-0',
                  )}
                  width={133}
                  height={115}
                  enabledLazyLoading={false}
                />
                <Picture
                  imgSrc={`${projectConfig.cloudinaryProjectName}/collapo-logo`}
                  alt="Collapo logo"
                  classNameImage="w-auto h-[80px] md:h-[115px]"
                  classNameWrapper={clsx(
                    'absolute inset-0 mx-[22px] w-full transition-all duration-700 motion-reduce:transition-none',
                    bgChangeThemed ? 'opacity-0' : 'opacity-100',
                  )}
                  width={160}
                  height={60}
                  enabledLazyLoading={false}
                />
              </Link>
            </div>
            <div className="flex w-full items-center justify-end px-4">
              <nav className="absolute right-4 top-full z-50 w-full min-w-[250px] bg-collapo-white py-5 shadow max-xl:hidden lg:py-0 xl:static xl:block xl:w-full xl:max-w-full xl:bg-transparent xl:px-6 xl:shadow-none">
                <ul className="block lg:flex">
                  {memoMenuItems
                    .filter(({ isButton }) => !isButton)
                    .map(({ link, subLink }, index) => (
                      <Fragment
                        key={`memoMenuItems-isNotButton-header-closed-${index}`}
                      >
                        <ListItem>
                          <LinkItem
                            {...(subLink.length > 0 && { dropdown: true })}
                            navLink={link.path}
                            activePath={activePath}
                            item={link}
                            bgChangeThemed={bgChangeThemed}
                          />
                          {subLink.length > 0 && (
                            <Dropdown>
                              {subLink.map(
                                ({ label, path, openInNewTab }, index) => (
                                  <Fragment
                                    key={`memoMenuItems-subHeader-${index}`}
                                  >
                                    <DropdownItem
                                      path={path}
                                      label={label}
                                      openInNewTab={openInNewTab}
                                      activePath={activePath}
                                    />
                                  </Fragment>
                                ),
                              )}
                            </Dropdown>
                          )}
                        </ListItem>
                      </Fragment>
                    ))}
                </ul>
              </nav>
              <div className="hidden justify-end pr-16 sm:flex xl:pr-0">
                {memoMenuItems
                  .filter(({ isButton }) => isButton)
                  .map(({ link }, index) => (
                    <Fragment
                      key={`memoMenuItems-header-closed-isButton-${index}`}
                    >
                      <Link
                        href={link.path}
                        className={clsx(
                          'px-6 py-2.5 text-menu-mobile font-medium transition-colors duration-300 motion-reduce:transition-none 2xl:text-menu',
                          bgChangeThemed
                            ? 'bg-collapo-white text-collapo-black hover:bg-collapo-white/90'
                            : 'bg-collapo-blue text-collapo-white hover:bg-collapo-blue-90',
                        )}
                        target={
                          width && width > 1024 && link.openInNewTab
                            ? '_blank'
                            : '_self'
                        }
                      >
                        {link.label}
                      </Link>
                    </Fragment>
                  ))}
              </div>
              <HamburgerButton
                toggled={isMenuOpen}
                toggle={setIsMenuOpen}
                color={bgChangeThemed ? 'white' : 'black'}
              />
            </div>
          </div>
          {isMenuOpen && (
            <div className="w-full xl:hidden">
              <div className="flex flex-col gap-y-[20px] py-8 xl:py-10">
                <nav className="grid w-full gap-y-[20px] overflow-y-auto xl:grid-cols-3 xl:gap-x-44 xl:overflow-y-hidden xl:pb-1">
                  {memoMenuItems
                    .filter(({ isButton }) => !isButton)
                    .map(({ link, subLink }, index) => (
                      <Fragment
                        key={`memoMenuItems-isNotButton-header-opened-${index}`}
                      >
                        <ListItem>
                          <LinkItem
                            {...(subLink.length > 0 && { dropdown: true })}
                            navLink={link.path}
                            activePath={activePath}
                            item={link}
                            type="big"
                          />
                          {subLink.length > 0 && (
                            <Dropdown>
                              {subLink.map(
                                ({ label, path, openInNewTab }, index) => (
                                  <Fragment
                                    key={`memoMenuItems-subHeader-opened-${index}`}
                                  >
                                    <DropdownItem
                                      path={path}
                                      label={label}
                                      openInNewTab={openInNewTab}
                                      activePath={activePath}
                                    />
                                  </Fragment>
                                ),
                              )}
                            </Dropdown>
                          )}
                        </ListItem>
                      </Fragment>
                    ))}
                </nav>
                <div className="mt-6 sm:hidden">
                  {memoMenuItems
                    .filter(({ isButton }) => isButton)
                    .map(({ link }, index) => (
                      <Fragment
                        key={`memoMenuItems-header-opened-isButton-${index}`}
                      >
                        <Link
                          href={link.path}
                          className="block w-full bg-collapo-blue px-6 py-2.5 text-center text-menu-mobile font-medium text-collapo-white transition-colors duration-300 hover:bg-collapo-blue-90 motion-reduce:transition-none 2xl:text-menu"
                          target={
                            width && width > 1024 && link.openInNewTab
                              ? '_blank'
                              : '_self'
                          }
                        >
                          {link.label}
                        </Link>
                      </Fragment>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
