import clsx from 'clsx';
import { ReactNode } from 'react';

interface DropdownProps {
  children: ReactNode;
}

const Dropdown = ({ children }: DropdownProps) => {
  return (
    <div
      className={clsx(
        'relative flex flex-wrap gap-y-1 transition-all group-hover:opacity-100 xl:invisible xl:absolute xl:left-0 xl:top-[115%] xl:min-w-[250px] xl:bg-collapo-white xl:p-4 xl:opacity-0 xl:shadow-lg xl:group-hover:visible xl:group-hover:top-full',
      )}
    >
      {children}
    </div>
  );
};

export default Dropdown;
